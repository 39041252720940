textarea,input{
  font-family:'EB Garamond', serif;
  font-size:18px;
}
.App {
  text-align: center;
  overflow-x: hidden;
}
.App-alt {
  text-align: center;
  overflow-x: hidden;
}
.bg01-banner{
  background-image: url('./images/bg01.jpg');
  background-size: "cover";
  height: 160px;
  margin: -10px;
  font-size:50px;
  background-repeat:no-repeat; 
  background-position:center;
}
.bg02-banner{
  background-image: url('./images/bg02.jpg');
  background-size: "cover";
  height: 160px;
  margin: -10px;
  font-size:50px;
  background-repeat:no-repeat; 
  background-position:center;
}
.bg03-banner{
  background-image: url('./images/bg03.jpg');
  background-size: "cover";
  height: 160px;
  margin: -10px;
  font-size:50px;
  background-repeat:no-repeat; 
  background-position:center;
}
.bg04-banner{
  background-image: url('./images/bg04.jpg');
  background-size: "cover";
  height: 160px;
  margin: -10px;
  font-size:50px;
  background-repeat:no-repeat; 
  background-position:center;
}
.bg05-banner{
  background-image: url('./images/bg05.jpg');
  background-size: "cover";
  height: 160px;
  margin: -10px;
  font-size:50px;
  background-repeat:no-repeat; 
  background-position:center;
}
.bg01{
  background-image: url('./images/bg01.jpg');
  background-size: "cover";
  height: 100vh;
  margin: -10px;
  font-size:50px;
  background-repeat:no-repeat; 
  background-position:center;
}
.bg02{
  background-image: url('./images/bg02.jpg');
  background-size: "cover";
  height: 100vh;
  margin: -10px;
  font-size:50px;
  background-repeat:no-repeat; 
  background-position:center;
}
.bg03{
  background-image: url('./images/bg03.jpg');
  background-size: "cover";
  height: 100vh;
  margin: -10px;
  font-size:50px;
  background-repeat:no-repeat; 
  background-position:center;
}
.bg04{
  background-image: url('./images/bg04.jpg');
  background-size: "cover";
  height: 100vh;
  margin: -10px;
  font-size:50px;
  background-repeat:no-repeat; 
  background-position:center;
}
.bg05{
  background-image: url('./images/bg05.jpg');
  background-size: "cover";
  height: 100vh;
  margin: -10px;
  font-size:50px;
  background-repeat:no-repeat; 
  background-position:center;
}
.bg1{
  background-image: url('./images/bg1.jpg');
  background-size: 100% 100%;
  height: 100vh;
  margin: -10px;
  font-size:50px;
  opacity: .85;
  background-repeat:no-repeat; 
  background-position-y:bottom;
}
.bg2{
  background-image: url('./images/bg2.jpg');
  background-size: 100% 100%;
  height: 100vh;
  margin: -10px;
  font-size:50px;
  opacity: .85;
  background-repeat:no-repeat; 
  background-position-y:bottom;
}
.bg3{
  background-image: url('./images/bg3.jpg');
  background-size: 100% 100%;
  height: 100vh;
  margin: -10px;
  font-size:50px;
  opacity: .85;
  background-repeat:no-repeat; 
  background-position-y:bottom;
}
.bg4{
  background-image: url('./images/bg4.jpg');
  background-size: 100% 100%;
  height: 100vh;
  margin: -10px;
  font-size:50px;
  opacity: .85;
  background-repeat:no-repeat; 
  background-position-y:bottom;
}
.bg5{
  background-image: url('./images/bg5.jpg');
  background-size: 100%;
  height: 100vh;
  margin: -10px;
  font-size:50px;
  opacity: .85;
  background-repeat:no-repeat; 
  background-position-y:top;
}

.bg6{
  background-image: url('./images/bg6.jpg');
  background-size: 100% 100%;
  height: 100vh;
  margin: -10px;
  font-size:50px;
  opacity: .85;
  background-repeat:no-repeat; 
  background-position-y:bottom;
}
.bg7{
  background-image: url('./images/bg7.jpg');
  background-size: 100% 100%;
  height: 100vh;
  margin: -10px;
  font-size:50px;
  opacity: .85;
  background-repeat:no-repeat; 
  background-position:center;
}
.bg8{
  background-image: url('./images/bg8.jpg');
  background-size: 100% 100%;
  height: 100vh;
  margin: -10px;
  font-size:50px;
  opacity: .85;
  background-repeat:no-repeat; 
  background-position:center;
}
.bg9{
  background-image: url('./images/bg9.jpg');
  background-size: 100% 100%;
  height: 100vh;
  margin: -10px;
  font-size:50px;
  opacity: .85;
  background-repeat:no-repeat; 
  background-position:center;
}
.bg0{
  background-image: url('./images/bg0.jpg');
  background-size: 100% 100%;
  height: 100vh;
  margin: -10px;
  font-size:50px;
  opacity: .85;
  background-repeat:no-repeat; 
  background-position: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  transform: rotate(30deg);
}
.nav-btn-logo{
  transform:rotate(10deg);
}

#bt-0.nav-btn-selected{
  background: radial-gradient(white, rgb(48, 112, 233))!important;
}
.nav-btn{
  background: rgb(44, 128, 128,.6);
  border-radius: 10px;
  height: 72px;
  width: 72px;
  border: inset 3px grey;
  font-size: 17px;
  color: wheat;
  font-family: 'EB Garamond', serif;
  padding-top:5px
}
.nav-btn#bt-14 svg path{
  stroke:wheat;
}
.nav-btn-selected#bt-10 svg path {
  fill:whitesmoke;
}
.nav-btn-selected#bt-11 svg path{
  fill:rgb(54, 223, 54);
} 
.nav-btn-selected#bt--2 svg path:nth-child(4), .nav-btn-selected#bt--2 svg path:nth-child(5) {
  stroke:rgb(54, 223, 54);
}
.nav-btn-selected#bt-12 svg path {
  fill:lightcyan;
}
.nav-btn-selected#bt-13 svg path:nth-child(4) {
  stroke:hotpink;
}
.nav-btn-selected#bt-13 svg path:nth-child(3) {
  stroke:gold;
}
.nav-btn-selected#bt-13 svg path:nth-child(2) {
  stroke:black;
}
.nav-btn-selected#bt-16 svg path:nth-child(4), .nav-btn-selected#bt-16 svg path:nth-child(5) {
  stroke:rgb(54, 223, 54);
}
#bt-17 svg polygon{
  fill:wheat;
}
#bt-17 svg g{
  fill:wheat;
}
.nav-btn#bt-17 svg circle{
  fill: wheat 
}
.nav-btn-selected#bt-17 svg g{
  fill:white;
}
.nav-btn-selected#bt-18 svg path{
  fill:red;
}
.nav-btn-selected#bt-19 svg path{
  stroke:goldenrod;
}
.nav-btn-selected#bt-19 svg path:nth-child(2){
  fill:goldenrod;
}
.nav-btn-selected#bt-15 svg path{
  fill:sienna
}

.nav-btn-selected#bt--1 svg path{
  fill:whitesmoke;
}
.white-w30-center{
  background:whitesmoke;
  width: 40px;
  height:30px;
  margin: auto;
  font-size:20px;
  padding-top:10px;
}
.tag-filter{
  font-size: 20px;
}
.center-num{
  width: 10px;
  margin: auto;
}
.nav-btn-exp{
  background:  #699abdb2;
  border-radius: 10px;
  height: 72px;
  width: 72px;
  border: inset 3px grey;
  font-family: 'EB Garamond', serif;
  padding-top:12px
}
.round{
  border-radius: 50%;
}
.face{
  height: 350px;
  width: 350px;
  margin:10px
}
.fade-border{
  height: 370px;
  width: 370px;
  border-radius: 50%;
  margin-left:25px;
  background: radial-gradient(gold,gold,gold,gold,wheat,rgba(255,255,255, 0.50),rgba(255,255,255, 0.15),rgba(255, 255, 255, 0.05));
}
.nav-btn-selected{
  background: rgb(44, 128, 128);
  border-radius: 10px;
  height: 72px;
  width: 72px;
  border: outset 3px grey;
  font-size: 20px;
  color: wheat;
  font-family: 'EB Garamond', serif;
  padding-top:5px
}
.back-btn{
  background: rgb(44, 128, 128);
  border-radius: 10px;
  height: 40px;
  width: 90px;
  border: outset 3px grey;
  font-size: 20px;
  color: wheat;
  font-family: 'EB Garamond', serif;
  padding-top:5px;
  margin-left:10px;
}
.ml-1{
  margin-left:10px;
}
.ml-2{
  margin-left:30px;
}
h1 {
  color: wheat;
  text-shadow: 2px 2px black;
  background:rgb(44, 128, 128,.4);
  margin: 10px 0;
  padding: 7px 0;
  font-size: 76px;
  font-family: 'EB Garamond', serif;
}
.banner-header h1{
  width:100%;
  padding:0px 180px;
  margin:-88px -30px 0px;
}
.article-title{
  font-weight:bold;
  color: rgb(44, 128, 128);
  text-decoration: underline;
  cursor: pointer;
}
button{
  cursor: pointer
}
.consult-btn{
  height: 120px;
  width: 300px;
  font-size:50px;
  font-weight:bold;
  color: aliceblue;
  background:radial-gradient(goldenrod,rgb(218, 27, 27),rgb(218, 27, 27),rgb(218, 27, 27),rgb(218, 27, 27),rgb(218, 27, 27));
  border-radius: 5px;
  margin-top: 15px;
  border: double 3px gold;
  font-family:'EB Garamond', serif;
  text-shadow: 2px 2px black;;
}

.title{
  font-size: 24px;
  font-weight: 600;
}
.section div, .section-alt div {
  text-align: center;
}

.section{
  background:rgb(44, 128, 128,.6);
  width:102vw;
  padding: auto;
  margin:10px -10px;
  color: white;
  padding: 5px;
  top:0;
  left:0;
  right:0;
  font-size:50px;
  scroll-snap-align: start;
}
.section-alt{
  background:rgb(44, 128, 128,.3);
  width:102vw;
  padding: auto;
  margin:10px -10px;
  color: white;
  padding: 5px;
  top:0;
  left:0;
  right:0;
  font-size:50px;
  scroll-snap-align: start;
}
#main{
  height: 200vh;
  
}
h2{
  background:rgb(44, 128, 128,.6);
  width: 90%;
  border-radius: 30px;
  margin:auto;
  margin-top:18px;
  color: white;
  font-size: 32px;
  padding:5px;
}
h3{
  background:rgb(44, 128, 128,.6);
  width: 380px;
  border-radius: 30px;
  margin:auto;
  margin-top:48px;
  color: white;
  font-size: 32px;
  padding:5px;
}

.row{
  display: flex;
  flex-direction: row;
  width: 90%;
  margin:auto
}
.page-nav-row{
  display:flex;
  flex-direction: row;
  margin:auto;
  width:170px;
}
.card-row{
  display: flex;
  justify-content: space-between;
  width: 350px;
  margin:30px auto;
}

.down{
  background: rgb(255, 255, 255,.6);
  width:70px;
  height:45px;
  margin: auto;
  margin-top: 40vh;
  border: solid 4px white;
  border-radius: 4px;
}
.calc{
  background: rgba(255, 255, 255, 0.538);
  padding: 0 40px 10px;
  margin-top:20px;
  width: 400px;
}
.calc label{
  width: 380px;
  display:flex;
  justify-content: space-between;
  margin-bottom: 14px;
}
.calc label input{
  width: 100px;
  font-size:20px;
}
.calc label select{
  width: 108px;
  font-size:20px;
}
.calc label span{
  width: 80px;
  font-size:20px;
  background-color: white;
  padding:7px;
}
.font20 {
  font-size:20px; 
}
.self{
  width:375px;
  height:500px;
  border: solid black 20px;
}
.explanation {
  background: rgba(245, 245, 245, 0.75);
  display: flex;
  flex-direction: column;
  width:90%;
  padding:10px;
  margin:auto;
  margin-top:-4px;
}
.values-plot{
  margin:10px 10px 0;
  align-items: center;
}
.explanation-expand {
  background: rgba(245, 245, 245, 0.75);
  display: flex;
  flex-direction: column;
  width:90%;
  padding:0 10px 10px;
  margin:auto;
  margin-top:-4px;
}
.explanation button {
  background-color: aliceblue;
}
.content-center{
  margin-left:calc((100vw - 375px)/4)
}
.center{
  margin: auto;
}
#preapproved-checkbox{
  display: flex;
  width: 350px;
  justify-content: space-between;
}
.home-form-box{
  display: flex;
  width: 450px;
  margin: auto;
  justify-content: space-between;
}
/* .home-form-row{

} */
.home-form-box .row {
justify-content: space-between;
}
.home-form-box .row input{
  height:20px;
  margin-top: 18px;
}
.aside{
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.25);
  justify-content: space-between;
  height: 1570px;
}
.col{
  display: flex;
  flex-direction: column;
}
.space{
  margin-left:25px;
}
.space-right{
  margin-right:25px;
}
.p{
  font-size: 20px;
  line-height: 30px;
  padding: 10px;
}
.article{
  background: linear-gradient(aliceblue,rgba(240, 248, 255, 0.9),rgba(240, 248, 255, 0.8),rgba(240, 248, 255, 0.7),rgba(240, 248, 255, 0.6),rgba(240, 248, 255, 0.5));
}
.article-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(wheat, rgb(44,128,128));
}
.article-container .article {
  border: solid 1px black;
}
.about-me-text{
  font-size:20px;
  text-align:center
}
.about-me-outer{
  overflow-y:hidden;
  margin:-10px;
  height:100vh!important;
}
.about-me-outer h1{
  text-align: center;
}
.profile{
  display:flex;
  flex-direction: column;
  margin-top:30px;
  width:680px;
  background: black;
}
.profile-container{
  width: 660px;
  background:rgba(0,0,0,.7);
  color:rgba(240, 200, 0, 0.85);
  text-align: left;
  padding-left:20px;
  font-weight: bold;
  font-family:'Courier New', Courier, monospace;

}
.map-header{
  font-size:36px;
  color: #2c8080;
  font-weight: bold;
  margin:10px;
}
.hidden{
  display: none;
}
.card{
  padding:0 20px;
  border: solid black 1px;
  background: white;
  width: 375px;
}
/* #first-section-content{
  height:c1
} */

.tooltip {
  position: relative;
  display: inline-block;
  cursor:pointer;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 70px;
  font-size:18px;
  background-color: rgba(0,0,0, 0.5);
  color: wheat;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    transform: rotate(30deg)
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
.bold{
  font-weight: bold;
}
.loader{
  border: 25px solid #f3f3f3;
  border-top: 25px solid rgb(44, 128, 128);
  border-radius: 50%;
  width: 320px;
  height: 320px;
  margin:auto;
  margin-top:180px;
  animation: spin 1s linear infinite;
  background-color: rgb(255, 255, 255,.5);
  z-index: 2;
}
.bg-gray{
  z-index:1;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color: rgba(244, 239, 239, 0.7);
}
.search-row{
  display: flex;
  margin-top:20px;
  justify-content: space-around;
  align-items: center;
  margin-left:-5%;
}
.search-box{
  width: 200px;
  height: 30px;
  border-radius: 5px;
  background-color: aliceblue;
  margin-left:5px;
}
.search-container{
  font-size:20px;
}
.content-box {
  font-size: 20px;
  margin:auto;
  width: 80vw;
  height: 500px;
  background: aliceblue;
  margin-top: 15px;
  padding: 10px 20px;
  overflow-y: scroll;
}
.article-box {
  position:absolute;
  bottom: 10px;
  top:200px;
  left:10px;
  right:10px;
  font-size: 20px;
  margin:auto;
  width: 80vw;
  background: aliceblue;
  margin-top: 15px;
  padding: 10px 20px;
  overflow-y: scroll;

}
.article-light {
  font-size: 20px;
  margin:auto;
  width: 80vw;
  height: 60vh;
  background: rgb(240, 248, 255,.8);
  margin-top: 15px;
  padding: 10px 20px;
  border: solid 10px rgba(255, 255, 255, 0.6);
  overflow-y:scroll;
}
.p-plus{
  text-indent: 40px;
  text-align:left;
  font-size: 20px;
  font-weight:500;
  line-height: 30px;
  padding: 10px;
  background:rgb(240, 248, 255,.3);
}

.table{
  border: solid 1px black;
  overflow-x:auto;
  background: white;
  width: 80vw;
  margin:auto;
}
.td{
  padding: 4px 16px;
  border: solid 1px black;
}
.gray{
  background: rgba(208, 216, 208, 0.4);
}
.main{
  background: rgb(44, 128, 128,.3)
}

.row-num{
  width: 100px;
}

.num-list{
  width: 50px;
}
.i{
  font-style: italic;
}
.b{
  font-weight: bold;
}
.u{
  text-decoration: underline;
}

.logo{
  height: 40px;
  transform: rotate(10deg)
}

.logo-top #svg2 {
  position:absolute;
  top: 61px;
  left: 30px;
  height: 120px;
  width: 120px;
  transform: rotate(10deg)
}
.card.space #svg2{
  margin-top:-50px;
  margin-left:100px;
  margin-right:auto;
  width:175px;
  height:175px;
}
.card.space{
  background-color: rgb(44, 128, 128,.5);
  font-weight: bold;
  color:white;
  border: solid 10px rgba(255, 255, 255, 0.8);
}
.card.space .p {
  font-size: 24px;
}
#svg2{
  margin:-15px -6px;
}
.inquiry-page{
  display: flex;
  justify-content: end;
  background-image: url('images/pexels-james-wheeler-1578750.jpg');
  background-size: 1500px;
  height: 90vh;
  padding:20px;
  background-repeat:no-repeat; 
  background-position:center;
  margin:-10px;
  border-radius:5px;
  min-height: 800px;
}
.inquiry-image{
  
  background-image: url('images/helmet.jpg');
  background-size: 1000px;
  height: 500px;
  background-repeat:no-repeat; 
  background-position:center;
  margin:80px 8vw 0px 0px;
}
.schedule-image{
  background-image: url('images/route66.jpg');
  background-size: 440px;
  height: 400px;
  background-repeat:no-repeat; 
  background-position:top;
  margin:0px 8vw 0px 0px;
}
.inquiry-form{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(44, 128, 128,.65);
  width: 350px;
  height: 500px;
  padding:20px 20px 0px;
  margin:auto;
  border-radius:5px;
}
.schedule-form{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(44, 128, 128,.65);
  width: 350px;
  height: 280px;
  padding:20px 20px 0px;
  margin:auto;
  border-radius:5px;
}
.form-select-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:20px;
  background-color: rgb(240, 255, 255,.5);
  height: 20px;
  margin-left: -20px;
  width: 350px;
}
.form-select-row select{
  height:30px;
  font-size: 18px;
  font-family: 'GB';
}
.form-select-row h4{
  font-size: 22px;
}
.iabs{
  font-size:10px;
  background:rgb(240, 255, 255,.75)
}

.form-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:20px;
  background-color: rgb(240, 255, 255,.75);
  height: 20px;
  margin-left: -20px;
  width: 350px;
}
.form-col{
  padding:0 20px 20px;
  background-color: rgb(240, 255, 255,.75);
  width:350px;
  margin-left:-20px;
}
.form-title{
  padding:20px 0 20px 0;
  background-color: rgb(44, 128, 128,.6);
  width:390px;
  color:rgb(249, 243, 151);
  border-radius: 3px;
  margin-top:3px;
  margin-bottom:15px;
  margin-left:-20px;
  text-align: center;
}
.form-row input{
  height: 20px;
}
.form-text{
  height:55px;
  width:303px;
}
.mt-1 {
  margin-top:20px;
}
.m-1 {
  margin: 20px;
}
.bg-gradient{
  background: linear-gradient(wheat, rgb(44,128,128));
  height: 100vh;
  overflow-y: hidden;
}
.banner-outer{
  margin:-8px;
  width:100vw;
  overflow-x:hidden;
}
.submit{
  background: rgb(44, 128, 128);
  border-radius: 10px;
  height: 40px;
  width: 90px;
  border: outset 3px grey;
  font-size: 20px;
  color: wheat;
  font-family: 'EB Garamond', serif;
  padding-top:5px;
  margin:5px 250px;
}
.submit-disabled{
  background-color:gainsboro !important;
  border-radius: 10px;
  height: 40px;
  width: 90px;
  border: outset 3px grey;
  font-size: 20px;
  color: black;
  font-family: 'EB Garamond', serif;
  padding-top:5px;
  margin:5px 250px;
}
.next-page{
  border-radius: 10px;
  height: 40px;
  width: 60px;
  border: outset 3px grey;
  font-size: 20px;
  color: wheat;
  font-family: 'EB Garamond', serif;
  background:rgb(44, 128, 128)
}
.next-page-disabled{
  background-color:gainsboro !important;
  border-radius: 10px;
  height: 40px;
  width: 60px;
  border: outset 3px grey;
  font-size: 20px;
  color: black;
  font-family: 'EB Garamond', serif;
}
.red-bg{
  background-color: rgb(246, 138, 138);
}
.home-form-header{
  width: 520px;
  border-radius:3px;
}
.home-form-container{
  background: rgba(255, 255, 255, 0.473);
  padding-bottom:30px;
}
#process{
  width:100%;
  background-color: rgb(44, 128, 128,.7);
}
#realtor-com-logo{
  width:55px;
  margin-top:-10px;
  opacity: .85;
}
.nav-btn-realtor{
  background-color: rgb(215, 43, 43);
  border-radius: 10px;
  height: 72px;
  width: 72px;
  border: inset 3px grey;
  font-family: 'EB Garamond', serif;
  padding-top:12px
}
.nav-row{
  height:72px;
  display: flex;
  justify-content: center;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.02)
  }
  20% {
    transform: scale(1.04)
  }
  30% {
    transform: scale(1.06)
  }
  40% {
    transform: scale(1.08)
  }
  50% {
    transform: scale(1.1)
  }
  65% {
    transform: scale(1.08)
  }
  80% {
    transform: scale(1.06)
  }
  90% {
    transform: scale(1.04)
  }
  100% {
    transform: scale(1.02);
  }
}
#animated-submit{
  animation: pulse infinite 2s;
  color: white;
  background: rgb(69, 161, 44);
  border: double 1px white !important;
}
.polish-chart{
  width: 825px;
}
.about-me{
  justify-content: flex-end;
}
@media screen and (max-width:1500px) {
  .profile{
    margin-top:calc(100vh - 700px);
    height:100%;
  }
  .about-me-outer{
    overflow-y: scroll;
  }
  .about-me{
    justify-content: center;
  }
}
@media screen and (max-width:700px) {
  h1{
    font-size: 60px;
    max-width:700px;
    height:88px;
  }
  .banner-header h1 {
    max-width:700px;  
  }
  .home-form-box{
    flex-direction: column;
    width:300px;
    margin:auto;
  }
  .center{
    margin-left:0px;
  }
  .round{
    margin-left: 10px;
  }
  .polish-chart{
    width: 700px;
  }
  .profile{
    margin-top:calc(100vh - 850px);
    width: 400px;
    height:100%;
  }
  .about-me-outer{
    overflow-y: scroll;
  }
  .about-me{
    justify-content: center;
  }
  .profile-container{
    width: 380px
  }
}
@media screen and (max-width:600px) {
  h1{
    font-size: 52px;
    max-width:600px;
    height:88px;
  }
  .banner-header h1 {
    max-width:600px;  }
    .polish-chart{
      width: 600px;
    }
}
@media screen and (max-width:550px) {
  h1{
    font-size: 48px;
    max-width:550px;
    height:88px;
  }
  .banner-header h1 {
    max-width:550px;  }
  .polish-chart{
    width: 550px;
  }
}
@media screen and (max-width:500px) {
  h1{
    font-size: 44px;
    max-width:500px;
    height:88px;
  }
  .banner-header h1 {
    max-width:500px;  }
  .polish-chart{
    width: 500px;
  }
}
@media screen and (max-width:450px) {
  h1{
    font-size: 40px;
    max-width:450px;
    height:88px;
  }
  .calc{
    width: 280px;
  }
  .font20{
    font-size:17px;
  }
  .calc label {
    width:300px;
  }
  .calc{
    padding:0 34px;
  }
  .banner-header h1 {
    max-width:400px;  }
  .bg5{
    background-size:cover;
  }
  .bg4{
    background-size:cover;
  }
  .bg3{
    background-size:cover;
  }
  .bg2{
    background-size:cover;
  }
  .bg1{
    background-size:cover;
  }
  .polish-chart{
    width: 450px;
  }
}
@media screen and (max-width:375px) {
  h1{
    font-size: 36px;
    max-width:400px;
    height:88px;
  }
  .banner-header h1 {
    max-width:400px;  }
  h3{
    font-size: 28px
  }
  .polish-chart{
    width: 375px;
  }
  .profile{
    margin-top:calc(100vh - 600px);
    width: 375px;
    height:100%;
  }
  .about-me-outer{
    overflow-y: scroll;
  }
  .about-me{
    justify-content: center;
  }
  .profile-container{
    width: 380px
  }
}
@media screen and (max-height:900px) and (max-width:600px) {
  .inquiry-image{
    width:380px;
    height:428px;
  }
  .inquiry-form{
    margin-left:-10px;
    height: 50vh
  }
  .form-title{
    padding:5px 0 5px 0;
    background-color: rgb(44, 128, 128,.6);
    width:390px;
    color:wheat;
    border-radius: 3px;
    margin-top:0px;
    margin-bottom:0px;
    margin-left:-20px;
    text-align: center;
  }
  .form-row{
    height: 1vh;
  }
  .submit{
    background: rgb(44, 128, 128);
    border-radius: 10px;
    height: 40px;
    width: 90px;
    border: outset 3px grey;
    font-size: 20px;
    color: wheat;
    font-family: 'EB Garamond', serif;
    padding-top:5px;
    margin:5px 0 0 60vw;
  }
}
@media screen and (max-height:900px) {
  .inquiry-image{
    margin:-20px 10px;
  }
  
  .form-title{
    padding:5px 0 5px 0;
    background-color: rgb(44, 128, 128,.6);
    width:390px;
    color:wheat;
    border-radius: 3px;
    margin-top:0px;
    margin-bottom:0px;
    margin-left:-20px;
    text-align: center;
  }
  .form-row{
    height: 1vh;
  }
  .submit{
    background: rgb(44, 128, 128);
    border-radius: 10px;
    height: 40px;
    width: 90px;
    border: outset 3px grey;
    font-size: 20px;
    color: wheat;
    font-family: 'EB Garamond', serif;
    padding-top:5px;
    margin:5px 0 0 245px;
  }
}
